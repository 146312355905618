import { autoinject } from "aurelia-framework";
import { CentralReportViewModelDto } from "models/CentralReportViewModelDto";
import { CentralPlanReportService } from "services/CentralPlanReportService";
import { PlanReportHeaderData } from "resources/plan-report-header/PlanReportHeaderData";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { ReportStatus } from "models/ReportStatus";
import { CentralReportUpdated } from "resources/plan-report-header/CentralReportUpdated";

@autoinject
export class CentralReportView {
    reportid: number;
    centralReport: CentralReportViewModelDto;
    isDownloadingList = false;
    isDownloadingDetail = false;
    isResettingPlan = false;
    isResettingReport = false;
    isRecalculatingPlan = false;
    isRecalculatingYPlanFromQ = false;
    isRecalculatingReport = false;
    isRecalculatingYReportFromQ = false;
    subscription: Subscription;
    constructor(public service: CentralPlanReportService, public ea?: EventAggregator) {}

    activate(params: {reportid: number}) {
        this.reportid = params.reportid;
    }

    async attached() {
        this.subscription = this.ea.subscribe(CentralReportUpdated, _ => this.loadReport(1));
        await this.loadReport(1);
    }

    detached() {
        this.subscription.dispose();
    }

    SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded: number) {
        /*
        Calls `updateReportLastPeriodFromModel()` for a single time
        when the report status is `Report Not Submitted`.

        `updateReportLastPeriodFromModel()` automatically calls `loadReport()`
        which causes the circular calling.
        */
        if( window.localStorage && lastPeriodValuesUpdateNeeded === 1 && this.centralReport.reportStatus !== ReportStatus.Submitted ) {
            if( !window.localStorage.getItem('firstLoad') ) {
                this.service.updateReportLastPeriodFromModel(this.reportid);
                window.localStorage['firstLoad'] = true;
            }
            else {
                window.localStorage.removeItem('firstLoad');
            }
        };
    }

    async getCentralReport(lastPeriodValuesUpdateNeeded: number) {
        this.centralReport = await this.service.getReport(this.reportid);
        this.SingleTimeLastPeriodValuesUpdate(lastPeriodValuesUpdateNeeded);
    }

    async loadReport(lastPeriodValuesUpdateNeeded: number) {
        this.getCentralReport(lastPeriodValuesUpdateNeeded);
    }
    
    get headerData() {
        if(!this.centralReport) return undefined;
        return new PlanReportHeaderData("Central Report", this.reportid, this.centralReport.description, this.centralReport.reportStatus, this.centralReport.organization, this.centralReport.reportingPeriod);
    }
}
